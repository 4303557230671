import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import { ROOT_PAGE_URL } from "@/utils/constants";
import type { GetStaticProps, InferGetStaticPropsType } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

/**
 * custom 404 page
 * @param props static props
 * @returns
 */
export default function Custom404(
  props: Readonly<InferGetStaticPropsType<typeof getStaticProps>>
) {
  const { t, i18n } = useTranslation(["public"], {
    bindI18n: "languageChanged loaded",
  });
  // bindI18n: loaded is needed because of the reloadResources call
  // if all pages use the reloadResources mechanism, the bindI18n option can also be defined in next-i18next.config.js
  useEffect(() => {
    i18n.reloadResources(i18n.resolvedLanguage, ["public"]);
  }, [i18n]);

  return (
    <div className="page-not-found-container py-2 py-md-5 px-2 px-md-0">
      <Row className="justify-content-center mx-0">
        <Col xs="12" md="5">
          <h2>{t("pageDoesntExist")}</h2>
          <div className="mt-5">
            <Link href={ROOT_PAGE_URL}>
              <PbButton>{t("toHomepage")}</PbButton>
            </Link>
          </div>
        </Col>
        <Col xs="12" md="5">
          <div className="d-flex justify-content-center">
            <img src="/svg/custom/other/404.svg" alt="404" height="500px" width="100%" style={{ fill: "#1976d2" }} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export const getStaticProps = (async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale!, "public")),
    },
  };
}) satisfies GetStaticProps<{}>;
